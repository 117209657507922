<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="orderData === undefined">
      <h4 class="alert-heading">حدث خطأ أثناء جلب بيانات الطلب</h4>
      <div class="alert-body">
        لم يتم العثور على الطلب .
        <b-link class="alert-link" :to="{ name: 'admin.orders.index' }">قائمة الطلبات</b-link>
      </div>
    </b-alert>

    <b-row v-if="orderData" class="invoice-preview invoice1" id="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0 d-print-none">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title text-right">
                  الطلب
                  <span class="invoice-number">#{{ orderData.order_number }}</span>
                </h4>
              </div>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mb-75 d-print-none"
                @click="printInvoice(orderData)"
              >
                طباعة
              </b-button>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing d-print-none" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body v-if="orderData" class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <table>
                  <tbody>
                    <tr v-if="orderData.shop && contactType == 'customer'">
                      <td class="pr-1">التاجر :</td>
                      <td>
                        <span class="font-weight-bold"> {{ orderData.shop.name }}</span>
                      </td>
                    </tr>

                    <tr v-if="orderData.contact && contactType == 'supplier'">
                      <td class="pr-1">العميل :</td>
                      <td>
                        <span class="font-weight-bold"> {{ orderData.contact.name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">عدد المنتجات:</td>
                      <td>
                        <span class="font-weight-bold"> {{ orderData.items.length }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="pr-1">اجمالي الكمية:</td>
                      <td>
                        <span class="font-weight-bold"> {{ orderData.total_qty }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">تاريخ الطلب:</td>
                      <td>
                        <span class="font-weight-bold"> {{ orderData.order_date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex">
                <div>
                  <h6 class="mb-2">تفاصيل الدفع:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">حالة الدفع:</td>
                        <td>
                          <span class="font-weight-bold">{{ orderData.payment_status }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="pr-1">حالة الطلب:</td>
                        <td>
                          <span class="font-weight-bold">{{ orderData.status }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">الاجمالي المستحق:</td>
                        <td>
                          <span class="font-weight-bold">{{ __formatMoney(orderData.grand_total) }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="12" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-table
                  :items="orderData.items"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                  class="position-relative"
                >
                  <template #cell(price)="data">
                    <span class="text-nowrap">
                      {{ __formatMoney(data.item.price) }}
                    </span>
                  </template>

                  <template #cell(total)="data">
                    <span class="text-nowrap">
                      {{ __formatMoney(data.item.total) }}
                    </span>
                  </template>
                  <template #cell(image)="data">
                    <img width="50" :src="data.item.image" :text="data.item.name" />
                  </template>
                </b-table>
              </b-col>
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2"></b-col>
              <!-- Col: Total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">المجموع:</p>
                    <p class="invoice-total-amount">
                      {{ __formatMoney(orderData.total) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">الخصم:</p>
                    <p class="invoice-total-amount">
                      {{ __formatMoney(orderData.discount) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">الضريبة:</p>
                    <p class="invoice-total-amount">
                      {{ __formatMoney(orderData.taxes) }}
                    </p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">الاجمالي:</p>
                    <p class="invoice-total-amount">
                      {{ __formatMoney(orderData.grand_total) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">ملاحظات: </span>
            <span>{{ orderData.buyer_note }}</span>
            <span>{{ orderData.admin_note }}</span>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onMounted, computed } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle
  },
  data() {
    return {
      number: "",
      numberRange: "",
      numberRegx: ""
    };
  },

  watch: {},
  mounted() {},
  created() {},
  destroyed() {},
  methods: {},
  setup() {
    const refFormObserver = ref(null);
    const tableColumns = [
      { key: "id", label: "#", sortable: false },
      { key: "name", sortable: false },
      { key: "image", sortable: false },
      { key: "price", sortable: false },
      { key: "quantity", sortable: false },
      { key: "total", sortable: false }
      // { key: 'actions' },
    ];
    const contactType = computed(() => store.state.auth.contactType);
    // const user = computed(() => store.state.auth?.user);
    const orderData = ref(null);
    // const printInvoice = () => {
    //   window.print();
    // };
    onMounted(() => {
      //  orderData.value = store.state.orders.order;
    });

    store
      .dispatch("orders/fetchOrder", {
        id: router.currentRoute.params.id,
        params: {
          is_edit: true
        }
      })
      .then(response => {
        orderData.value = response.data.data;
      })
      .catch(error => {
        // if (error.response.status === 404) {
        //   //  orderData.value = undefined;
        // }
      });

    const printInvoice = orderData => {
      var tab = document.getElementById("invoice-preview");
      var style = `  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.rtl.min.css"
        integrity="sha384-nU14brUcp6StFntEOOEBvcJm4huWjB0OcIeQ3fltAfSmuZFrkAif0T+UtNGlKKQv" crossorigin="anonymous"><link rel="stylesheet" href="/css/styleInvoice.css" />`;
      style += `<style>  body {
            width: 100%;
            margin: 0;
            direction: rtl;
            text-align: right;
        }
        .row{
               
                 margin-left:0rem;
    margin-right: 0rem;
        }
           @page {
                size: auto; 
                margin: 5mm 5mm 5mm 5mm; 

            }
            .d-print-none{
              display:none;
            }
            .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
        padding-left: 0rem;
    padding-right: 0rem;
}
            .col-lg-12,.col-lg-4,col-lg-6 {
   
        padding-left: 0rem;
    padding-right: 0rem;
}
.order-md-2 {
    order: 2!important;
}
       </style>`;

      var headerhtml = `
            <div id="invoice"> <div class="invoice overflow-auto">  
            
             <div class="invoice-box">
        <header style="width: 100%;">
            <div class="row">
                <div class="col-lg-4 col-4">
                    <h4 class="invoice-id">متجر ادفيس للالعاب</h4>
                 
                    <div class="gry-color font-16"><strong> البريد الاكتروني: </strong>
                        Info@gamedif.com</div>
                    <div class="gry-color font-16"><strong>رقم الجوال:</strong>
                        966559822062</div>
                </div>
                <div class="col-lg-4 col-4 company-details">
                   <h4>#${orderData.order_number}</h4>
                    

                </div>
                <div class="col-lg-4 col-4 company-detail-logo mt-0">
                    <a> <img src="https://shop.gamedif.com/uploads/all/rhTLOOW5Uq4nMELyzGTQPgNSGCuBxwb7iQqNYsfU.png" class="logo" data-holder-rendered="true"
                            style="width:150px;margin-top: 16px;" /> </a>

                </div>

            </div>
        </header>`;

      headerhtml += `<div class="row"> <div class="col-lg-12" style=""> ${tab.outerHTML} </div>  </div>`;
      headerhtml += `    <footer>
        <div class="row">
            <div class="col-lg-12 col-12" style="">
                <h5 style="font-size: 15px;" class="m-b-0 "> التاريخ <span
                        class="text-primary">${new Date().toString()}</span> </h5>
            </div>
           
        </div>
    </footer> </div>  </div> </div>`;
      var win = window.open("", "", "fullscreen=yes,titlebar=yes,scrollbars=yes");
      const url = "";
      var name = "printers";

      //   addStyles(win, styles);

      win.document.write(style); //  add the style.
      win.document.write(headerhtml);

      setTimeout(() => {
        //   win.document.close();
        win.document.close();
        win.print();
      }, 1000);
    };

    return {
      orderData,
      tableColumns,
      contactType,
      printInvoice
    };
  }
};
</script>

<style lang="scss">
@import "~@resources/scss/vue/libs/vue-select.scss";
@import "~@resources/scss/vue/libs/vue-flatpicker.scss";
.order-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@resources/scss/base/pages/app-invoice.scss";
@import "~@resources/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
